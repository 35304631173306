import React, { useState, useEffect } from 'react';
import { Social } from '../../../../blocks/acf/Social';
import NextNavLink from '../../../util/NextNavLink';
import useWindowDimensions from '../../../../util/windowDimensions';

const FooterNavigation = ({ menus, options }) => {
    const { height, width } = useWindowDimensions();

    return (
        <div className="bg-black-100 flex relative h-full flex-col  z-[20]">
            <div className="xl:w-[1px] left-[3.333%] absolute h-full bg-grid"></div>
            <div className="xl:w-[1px]  absolute  h-full left-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  absolute  h-full right-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px] right-[3.333%] absolute h-full bg-grid"></div>

            <div className="absolute flex justify-between w-full h-full px-3 xl:hidden">
                <div className="w-[1px] bg-grid"></div>
                <div className="w-[1px]  bg-grid"></div>
            </div>
            <div className="px-3 xl:px-[3.333%] flex justify-between relative z-[1]">
                <div className="flex xl:flex-row flex-col   w-full text-white  xl:h-[672px] ">
                    <div className="flex items-center justify-center w-full h-full border-b border-grid xl:border-none pt-14 pb-11 xl:py-0">
                        <div className="flex items-center justify-center w-full h-full px-6">
                            <div className="flex flex-col items-center justify-center w-full h-full">
                                <div className="flex wow fadeInUp">
                                    <img
                                        src={
                                            options?.mainMenuOptions?.footer
                                                ?.footerLogo?.mediaItemUrl
                                        }
                                    />
                                </div>
                                <div
                                    className="mt-8 text-center lead--Regular wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: options?.footerContactInfo
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full h-full ">
                        <div className="flex flex-col items-center w-full h-full md:flex-row xl:flex-col ">
                            {menus?.slice(0, 1)?.map((item, index) => {
                                return (
                                    <div
                                        className={`w-full h-full flex  justify-center border-b  border-grid xl:min-h-[304px] xl:max-h-[304px] ${
                                            index === 0
                                                ? 'md:border-r border-grid xl:border-b xl:border-r-0 '
                                                : ''
                                        }`}
                                        key={index}
                                    >
                                        <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                            <p className="label--largeBold text-sky-100 wow fadeInUp">
                                                {item?.title}
                                            </p>
                                            <div className="flex flex-col space-y-2 text-center ">
                                                {item?.children?.map(
                                                    (subItem, subIndex) => {
                                                        return (
                                                            <NextNavLink
                                                                key={subIndex}
                                                                href={
                                                                    subItem?.path
                                                                }
                                                                target={
                                                                    subItem?.target
                                                                }
                                                            >
                                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp">
                                                                    {' '}
                                                                    {
                                                                        subItem?.title
                                                                    }
                                                                </a>
                                                            </NextNavLink>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {menus
                                ?.slice(
                                    width < 1340 ? 1 : 2,
                                    width < 1340 ? 2 : 3
                                )
                                ?.map((item, index) => {
                                    return (
                                        <div
                                            className={`w-full h-full flex  justify-center  border-b xl:border-none border-grid xl:min-h-[368px] xl:max-h-[368px]`}
                                            key={index}
                                        >
                                            <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                                <p className="label--largeBold text-sky-100 wow fadeInUp">
                                                    {item?.title}
                                                </p>
                                                <div className="flex flex-col space-y-2 text-center">
                                                    {item?.children?.map(
                                                        (subItem, subIndex) => {
                                                            return (
                                                                <NextNavLink
                                                                    key={
                                                                        subIndex
                                                                    }
                                                                    href={
                                                                        subItem?.path
                                                                    }
                                                                    target={
                                                                        subItem?.target
                                                                    }
                                                                >
                                                                    <a className="lead--Regular hover:text-sky-100 wow fadeInUp">
                                                                        {' '}
                                                                        {
                                                                            subItem?.title
                                                                        }
                                                                    </a>
                                                                </NextNavLink>
                                                            );
                                                        }
                                                    )}
                                                    {width < 1340 ? (
                                                        <>
                                                            <div className="opacity-50 h-[24px]  wow fadeInUp">
                                                                <p className=" lead--Regular mt-[-4px]">
                                                                    __
                                                                </p>
                                                            </div>
                                                            <Social
                                                                options={
                                                                    options?.socialMediaLinks
                                                                }
                                                                spaceBetween={
                                                                    'space-x-4'
                                                                }
                                                                hover={
                                                                    'hover:text-sky-100'
                                                                }
                                                            />
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full h-full ">
                        <div className="flex flex-col items-center w-full h-full md:flex-row xl:flex-col ">
                            {menus
                                ?.slice(
                                    width < 1340 ? 2 : 1,
                                    width < 1340 ? 3 : 2
                                )
                                ?.map((item, index) => {
                                    return (
                                        <div
                                            className={`w-full h-full flex justify-center px-6 border-b border-grid  xl:min-h-[304px] xl:max-h-[304px] ${
                                                index === 0
                                                    ? 'md:border-r md:border-b-0 border-grid  xl:border-b xl:border-r-0'
                                                    : ''
                                            }`}
                                            key={index}
                                        >
                                            <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                                <p className="label--largeBold text-sky-100 wow fadeInUp">
                                                    {item?.title}
                                                </p>
                                                <div className="flex flex-col space-y-2 text-center">
                                                    {item?.children?.map(
                                                        (subItem, subIndex) => {
                                                            return (
                                                                <NextNavLink
                                                                    key={
                                                                        subIndex
                                                                    }
                                                                    href={
                                                                        subItem?.path
                                                                    }
                                                                    target={
                                                                        subItem?.target
                                                                    }
                                                                >
                                                                    <a className="lead--Regular hover:text-sky-100 wow fadeInUp">
                                                                        {' '}
                                                                        {
                                                                            subItem?.title
                                                                        }
                                                                    </a>
                                                                </NextNavLink>
                                                            );
                                                        }
                                                    )}
                                                    {width < 1340 ? null : (
                                                        <>
                                                            <div className="opacity-50 h-[24px]">
                                                                <p className=" lead--Regular mt-[-4px]">
                                                                    __
                                                                </p>
                                                            </div>
                                                            <Social
                                                                options={
                                                                    options?.socialMediaLinks
                                                                }
                                                                spaceBetween={
                                                                    'space-x-[18px]'
                                                                }
                                                                hover={
                                                                    'hover:text-sky-100'
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            {menus?.slice(3, 4)?.map((item, index) => {
                                return (
                                    <div
                                        className={`w-full h-full flex justify-center  px-6 border-grid xl:min-h-[368px] xl:max-h-[368px]`}
                                        key={index}
                                    >
                                        <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                            <p className="label--largeBold text-sky-100 wow fadeInUp">
                                                {item?.title}
                                            </p>
                                            <div className="flex flex-col space-y-2 text-center">
                                                {item?.children?.map(
                                                    (subItem, subIndex) => {
                                                        return (
                                                            <NextNavLink
                                                                key={subIndex}
                                                                href={
                                                                    subItem?.path
                                                                }
                                                                target={
                                                                    subItem?.target
                                                                }
                                                            >
                                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp">
                                                                    {' '}
                                                                    {
                                                                        subItem?.title
                                                                    }
                                                                </a>
                                                            </NextNavLink>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterNavigation;
