import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../../util/NextNavLink';
import { Dropdown } from '../dropdown';
import { useRouter } from 'next/router';

function Navigation({
    menuItems,
    subMenu,
    sticky,
    variant,
    ctaMenu,
    headerTheme = '',
    hideNav
}) {
    //For mobile menu
    const [showMenu, setShowMenu] = useState(true);
    const [CTAOpen, setCTAOpen] = useState(false);
    const [active, setActive] = useState();

    //for search results

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > 100) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, []);

    const history = useRouter();
    let items = ctaMenu?.filter(
        (menu, index) => menu?.acf_cta_menu?.hideOnDesktop !== true
    );

    return (
        <>
            {' '}
            {!hideNav && (
                <nav
                    className={` w-full xl:block hidden  transition-all duration-300 bg-white fixed z-[98] ${
                        showMenu ? 'pt-12' : 'pt-0'
                    }`}
                >
                    <div className="flex items-center justify-between text-black-100 lg:px-[3.333%] lg:h-[80px]   lg:max-h-[80px] border-b border-grid">
                        <div className="flex w-full cursor-pointer lg:max-w-[16.715%] justify-center lg:border-l lg:border-r border-grid h-full">
                            <img
                                onClick={() => history.push('/')}
                                src="/logos/CP_Fitness_Stacked.svg"
                                width="128"
                                height="41.5"
                                alt="Chelsea Piers Fitness"
                            />
                        </div>
                        <div className="flex w-full justify-between items-center text-center pl-10 pr-[35px] border-r border-grid h-full ">
                            {menuItems?.map((menu, index) => {
                                return (
                                    <div
                                        className="label--bold h-full flex   transition-all duration-300  relative hover:border-cobalt-100 border-white border-b-[3px]"
                                        key={index}
                                        onMouseEnter={() =>
                                            setActive(menu?.key)
                                        }
                                        onMouseLeave={() => setActive('')}
                                    >
                                        <div className=" py-[30px] space-x-[8px] h-full flex hover:text-cobalt-100  transition-all duration-300 ">
                                            {menu?.url ? (
                                                <NextNavLink
                                                    target={menu?.target}
                                                    href={menu?.url || '#'}
                                                >
                                                    <a className="">
                                                        {' '}
                                                        {menu?.label}{' '}
                                                    </a>
                                                </NextNavLink>
                                            ) : (
                                                <div className="cursor-pointer">
                                                    {menu?.label}
                                                </div>
                                            )}

                                            {menu?.children?.length > 0 ? (
                                                <i className="transform -rotate-90 cp-icon-arraySmallLeft text-9 leading-16 font-700" />
                                            ) : null}
                                        </div>
                                        {menu?.children?.length > 0 ? (
                                            <Dropdown
                                                key={index}
                                                menuItem={menu}
                                                active={active === menu?.key}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                            {items?.map((item, index) => {
                                return (
                                    <NextNavLink
                                        target={item?.target}
                                        href={item?.path}
                                    >
                                        <a className="lg:max-w-[188px] mr-[-6px] w-full h-full  flex items-center label--bold btn btn--cobalt max-h-12">
                                            {item?.title}
                                        </a>
                                    </NextNavLink>
                                );
                            })}
                        </div>
                    </div>
                </nav>
            )}
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[3.333%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[18.88866666666667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[50%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[18.88866666666667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[3.333%] bg-grid"></div>
            <div className="w-[1px] xl:hidden  z-[3]  fixed h-full bg-grid left-3"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid left-1/2"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid right-3"></div>
        </>
    );
}

export default Navigation;
