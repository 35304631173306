import React, { useEffect, useState, useContext } from 'react';
import FooterMultiNavigation from './navigation';
import FooterMultiSubMenu from './subMenu';



export default function Footer(props) {


    const { menus, options, layoutType, defaults } = props;
    let footerMenu = [];
    let footerSubMenu = [];
      




    if(menus['footer-menu']) {
        footerMenu = menus['footer-menu'].menuItems;
    }

    if(menus['footer-sub-menu']) {
        footerSubMenu = menus['footer-sub-menu'].menuItems;
    }

    return (
        <>
            <FooterMultiNavigation 
                menus={footerMenu}
                options={options}
                props={props}
            />
            <FooterMultiSubMenu 
            subMenus={footerSubMenu}
            options={options}
            />
        </>
    );
}
