import React, { useState, useEffect } from 'react';
import { Social } from '../../../../blocks/acf/Social';
import NextNavLink from '../../../util/NextNavLink';
import useWindowDimensions from '../../../../util/windowDimensions';

const FooterMultiSubMenu = ({ subMenus, options }) => {
    const { height, width } = useWindowDimensions();

    return (
        <div className="bg-black-100 flex relative h-full flex-col  z-[20]">
            <div className="xl:flex hidden absolute h-full    justify-between w-full   px-[3.333%] ">
                <div className="w-[1px] bg-grid"></div>
                <div className="w-[1px]  bg-grid"></div>
            </div>
            <div className="xl:hidden flex absolute h-full    justify-between w-full   px-3">
                <div className="w-[1px] bg-grid"></div>
                <div className="w-[1px]  bg-grid"></div>
            </div>
            <div className="px-3 xl:px-[3.333%] flex lg:justify-between   relative z-[1] ">
                <div className="text-white border-t border-grid flex flex-col lg:flex-row w-full items-center justify-center py-6 space-y-4 lg:space-y-0 lg:py-[27px] text-opacity-50 label--smallBold  lg:space-x-12">
                    <p className="hover:text-sky-100">
                        {/* {options?.mainMenuOptions?.footer?.footerCopyright} */}
                        © {new Date().getFullYear()} CHELSEA PIERS. ALL RIGHTS RESERVED.
                    </p>
                    {subMenus
                        ?.slice(width < 1340 ? 1 : 0, width < 1340 ? 2 : 1)
                        ?.map((subItem, subIndex) => {
                            
                            return (
                                <NextNavLink
                                    key={subIndex}
                                    href={subItem?.path}
                                    target={subItem?.target}
                                >
                                    <a className="hover:text-sky-100">
                                        {' '}
                                        {subItem?.title}
                                    </a>
                                </NextNavLink>
                            );
                        })}
                    <div className="flex items-center space-x-6 lg:space-x-12">
                        {subMenus
                            ?.slice(width < 1340 ? 0 : 1, width < 1340 ? 1 : 2)
                            ?.map((subItem, subIndex) => {
                                return (
                                    <NextNavLink
                                        key={subIndex}
                                        href={subItem?.path}
                                        target={subItem?.target}
                                    >
                                        <a className="hover:text-sky-100">
                                            {' '}
                                            {subItem?.title}
                                        </a>
                                    </NextNavLink>
                                );
                            })}
                        {subMenus
                            ?.slice(width < 1340 ? 2 : 2, width < 1340 ? 3 : 3)
                            ?.map((subItem, subIndex) => {
                                return (
                                    <NextNavLink
                                        key={subIndex}
                                        href={subItem?.path}
                                        target={subItem?.target}
                                    >
                                        <a className="hover:text-sky-100">
                                            {' '}
                                            {subItem?.title}
                                        </a>
                                    </NextNavLink>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div className="px-3 xl:px-[3.333%] flex lg:justify-between   relative z-[1] lg:hidden ">
                                <div className="flex w-full  border-t border-grid h-[12px]">

                                </div>
            </div>
        </div>
    );
};

export default FooterMultiSubMenu;
