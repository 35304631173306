import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import NextNavLink from '../../../util/NextNavLink';
import { useDebounce, useClickOutside } from '../../../../util/hooks';

/* A single, simple Tailwind UI dropdown menu */
export function Dropdown({ menuItem, active, classes, style }) {
    const ref = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useClickOutside(ref, () => setTimeout(() => setDropdownOpen(false)));
    return (
        <Transition
            ref={ref}
            show={active}
            enter="transition duration-100 ease-out"
            enterFrom=" scale-95 opacity-0"
            enterTo=" scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom=" scale-100 opacity-100"
            leaveTo=" scale-95 opacity-0"
            className={`duration-300 origin-top`}
        >

            <div className={`flex flex-col pt-[25px] pb-[23px] space-y-[18px] w-[336px] z-[1000] items-start px-6 absolute transform -translate-x-1/2  top-20 bg-white  max-w-[336px] border border-grid ${classes}`}
                 style={style}
            >
                {menuItem?.children?.map((childMenuItem, index) => (
                    <div className={`flex ${
                        childMenuItem.children?.length > 0  
                            ? 'flex-col'
                            : ''
                    }`} key={index}>
                        <NextNavLink
                            key={`dd-p-${index}`}
                            href={childMenuItem.url || childMenuItem.path}
                            target={childMenuItem.target}
                        >
                            <a className="whitespace-nowrap flex items-center space-x-3  group ">
                                <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                    {' '}
                                </div>
                                <span className="group-hover:text-cobalt-100">{childMenuItem.title} </span>
                            </a>
                        </NextNavLink>
                        {childMenuItem.children?.length > 0 
                            ? <div className='space-y-[18px] flex flex-col py-[8px] self-start '>
                                {childMenuItem.children.map((innerItem, iiId) => (
                                    <div className='flex items-center'>
                                        <NextNavLink
                                            key={`dd-c-${index}-${iiId}`}
                                            href={innerItem.url || innerItem.path}
                                            target={innerItem.target}
                                        >
                                            <a className="whitespace-nowrap flex items-center space-x-3  group ">
                                                <div className="h-[1px] bg-cobalt-100 w-2 ml-4 group-hover:w-6 transition-all duration-300">
                                                    {' '}
                                                </div>
                                                <span className="group-hover:text-cobalt-100">{innerItem.title} </span>
                                            </a>
                                        </NextNavLink>
                                    </div>
                                ))}
                               </div>

                            :<></>}
                    </div>
                ))}
            </div>
            <div  className='hidden invisible bg-cobalt-100 w-6 group-hover:w-8'/>
        </Transition>
    );
}
