import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/*
 * This is a utility class used to inject active class into a Bootstrap
 * nav link for initial prototype: may be reusable elsewhere.
 */
const NextNavLink = ({ href: linkto, target, children, passHref, activeClassName}) => {
    const [href, setHref] = useState('#')

    useEffect(() => {
        let _href = linkto || '/'
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
            _href = _href.replace(
                'https://enfusion-api.webworkinprogress.com',
                'http://localhost:3000'
            );
        }
        // if (location.search) {
        //     _href += location.search
        // }
        setHref(_href)
    }, []);
   

    const router = useRouter();

    let className = children.props.className || '';

    if (href !== '#' && router?.asPath.split('#')[0] === href?.split('#')[0]) {
        className = `${className} ${activeClassName ? activeClassName : ''}`;
    }

    const voidLink = href && href.startsWith('#');
    return voidLink ? (
        React.cloneElement(children, { className, href, target })
    ) : (
        <Link href={href} passHref={passHref}>
            
            {React.cloneElement(children, { className, target })}
        </Link>
    );
};

export default NextNavLink;
