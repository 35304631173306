import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../../util/NextNavLink';
import { MobileDropdown } from '../dropdown/MobileDropdown';
import { Social } from '../../../../blocks/acf/Social';
import { useRouter } from 'next/router';
import { Dropdown } from '../dropdown';

function TopNav({
                    menuItems,
                    subMenu,
                    hybridTopNav,
                    hybridHamburgerNav,
                    sticky,
                    ctaMenu,
                    options,
                    variant,
                    headerTheme = '',
                    hideNav
                }) {
    //For mobile menu
    const [showMenu, setShowMenu] = useState(true);
    const [CTAOpen, setCTAOpen] = useState(false);
    const [showMobileHeader, setShowMobileHeader] = useState(false);
    const [topHeader, setTopHeader] = useState(false);
    const [active, setActive] = useState(false);
    const ref = useRef();

    //for search results

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > 100) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }
        }
    };

    const history = useRouter();

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, []);
    return (
        <>
            {!hideNav && (
                <header
                    className={`fixed   left-0 w-full  z-[99] transition-all duration-300 bg-black-100 ${
                        showMenu ? 'top-0' : 'lg:-top-14 top-0'
                    } `}
                >
                    <div className="hidden lg:flex items-center justify-between overflow-hidden h-[48px] max-h-[48px] px-[3.333%]">
                        <div className="flex items-center w-full h-full justify-items-start">
                            {subMenu?.map((item, index) => {
                                return (
                                    <NextNavLink
                                        href={item?.path || '#'}
                                        key={index}
                                    >
                                        <a
                                            className={`w-full text-12 leading-20 font-united font-600 tracking-widest uppercase text-opacity-70  border-grid  lg:py-4  ${
                                                item?.path === '/'
                                                    ? 'bg-white text-cobalt-100 text-center text-opacity-100 font-700 border-grid border-r'
                                                    : 'border-l border-r hover:text-cobalt-100 transition-all duration-300 hover:bg-buttonGray text-center text-white'
                                            } `}
                                        >
                                            {item?.title}
                                        </a>
                                    </NextNavLink>
                                );
                            })}
                        </div>
                        <div className="flex w-full max-w-[8.5vw] justify-end h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <NextNavLink
                                    href={
                                        '/search'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 px-[20px] py-5px leading-20 font-united font-600">
                                        <img src="/icons/Search.png"/>
                                    </a>
                                </NextNavLink>
                            </div>
                        </div>
                        <div className="flex w-full justify-end w-[200px] h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <i className=" cp-icon-Account text-24 leading-24" />

                                <NextNavLink
                                    href={
                                        '/my-membership'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 leading-20 font-united font-600">
                                        My Account
                                    </a>
                                </NextNavLink>
                            </div>
                        </div>
                    </div>

                    <div className=" xl:hidden flex items-center justify-between w-full  h-[64px] max-h-[64px] border-b border-grid   bg-white">
                        <div className="flex items-center justify-items-start w-full border-r border-grid   h-full min-w-[264px] lg:max-w-[16.715%]">
                            <div className="flex items-center w-full h-full px-6">
                                <img
                                    className="cursor-pointer"
                                    src="/logos/CP_Fitness_Horiz.svg"
                                    width="216"
                                    height="17.25"
                                    alt="Chelsea Piers Fitness"
                                    onClick={() => history.push('/')}
                                />
                            </div>
                        </div>

                        <div className="hidden lg:flex w-full justify-between items-center text-center pl-10 pr-[35px] border-r border-grid h-full ">
                            {hybridTopNav?.map((menu, index) => {
                                return (
                                    <div
                                        className="label--bold h-full flex   transition-all duration-300  relative hover:border-cobalt-100 border-white border-b-[3px]"
                                        key={index}
                                        onMouseEnter={() => {
                                            setActive(menu?.key);
                                        }}
                                        onMouseLeave={() => setActive('')}
                                    >
                                        <div className=" py-[30px] space-x-[8px] h-full flex hover:text-cobalt-100  transition-all duration-300 ">
                                            {menu?.url ? (
                                                <NextNavLink
                                                    target={menu?.target}
                                                    href={menu?.url || '#'}
                                                >
                                                    <a className="">
                                                        {' '}
                                                        {menu?.label}{' '}
                                                    </a>
                                                </NextNavLink>
                                            ) : (
                                                <div className="cursor-pointer">
                                                    {menu?.label}
                                                </div>
                                            )}

                                            {menu?.children?.length > 0 ? (
                                                <i
                                                    className="transform -rotate-90 cp-icon-arraySmallLeft text-9 leading-16 font-700"
                                                    style={{
                                                        marginTop: 'auto'
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                        {menu?.children?.length > 0 ? (
                                            <Dropdown
                                                key={index}
                                                menuItem={menu}
                                                active={active === menu?.key}
                                                classes=""
                                                style={{ marginTop: '-16px' }}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="flex w-full items-center justify-center h-full min-w-[96px] max-w-[96px]">
                            <div
                                className={`${
                                    !showMobileHeader ? 'mt-2.5' : ''
                                }`}
                                onClick={() =>
                                    setShowMobileHeader(!showMobileHeader)
                                }
                            >
                                {showMobileHeader ? (
                                    <img src="/images/closeButton.png" alt="" />
                                ) : (
                                    <i className=" cp-icon-Menu text-black-100 text-30" />
                                )}
                            </div>
                        </div>
                    </div>
                    <Transition
                        className=""
                        show={showMobileHeader}
                        enter="transition ease-out duration-100"
                        enterFrom=" opacity-0 scale-100"
                        enterTo=" opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom=" opacity-100 scale-100"
                        leaveTo=" opacity-0 scale-100"
                    >
                        <div className="xl:hidden bg-white   flex justify-between h-full h-[48px] relative ">
                            <div className="flex flex-col relative   w-full min-w-[264px]">
                                <div
                                    className="flex justify-between items-center h-full  min-h-[48px] border-r border-grid border-b px-6 label--bold "
                                    onClick={() => {
                                        setTopHeader(!topHeader);
                                        setActive('');
                                    }}
                                >
                                    <div className="text-cobalt-100">
                                        Fitness
                                    </div>
                                    {topHeader ? (
                                        <i className="inline-block transform rotate-90 cp-icon-arraySmallLeft text-9"></i>
                                    ) : (
                                        <i className="inline-block transform -rotate-90 cp-icon-arraySmallLeft text-9"></i>
                                    )}
                                </div>
                                <Transition
                                    className="border-grid bg-white transition-all origin-top top-12 min-w-[264px]  max-w-[calc(100vw-96px)] z-[12]  absolute w-full   flex flex-col px-6 pt-[25px] pb-[23px] space-y-[18px] label--bold"
                                    show={topHeader}
                                    enter="transition ease-in transform duration-300"
                                    enterFrom="transform opacity-0 scale-y-0"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    {subMenu?.map((item, index) => {
                                        return (
                                            <NextNavLink
                                                href={item?.path || '#'}
                                                key={index}
                                            >
                                                <a
                                                    className={`${
                                                        item?.path === '/'
                                                            ? 'text-cobalt-100'
                                                            : ''
                                                    } `}
                                                >
                                                    {item?.title}
                                                </a>
                                            </NextNavLink>
                                        );
                                    })}
                                </Transition>
                                <div
                                    className={` w-[100vw] top-0   min-w-full   border-grid ${
                                        topHeader ? 'bg-white' : 'bg-white'
                                    } `}
                                >
                                    <div
                                        className={`h-screen  w-full min-w-full relative transition-all duration-300  text-black-100 px-3 ${
                                            !topHeader
                                                ? 'bg-white'
                                                : 'bg-[rgba(0,0,0,0.7)]'
                                        } `}
                                    >
                                        <div
                                            className={`pt-[33px] pb-[168px] flex flex-col overflow-auto ${
                                                topHeader
                                                    ? ''
                                                    : 'border-r border-l'
                                            }  border-grid h-full  `}
                                        >
                                            <div className="flex flex-col justify-center space-y-6 lg:hidden label--largeBold">
                                                {/* Standard Mobile */}
                                                {menuItems?.map(
                                                    (menu, index) => {
                                                        return (
                                                            <div
                                                                className="flex flex-col justify-center h-full label--largeBold"
                                                                key={index}
                                                                onClick={() => {
                                                                    if (
                                                                        menu
                                                                            ?.children
                                                                            ?.length >
                                                                        0
                                                                    ) {
                                                                        setActive(
                                                                            active ===
                                                                            menu?.key
                                                                                ? ''
                                                                                : menu?.key
                                                                        );
                                                                        setTopHeader(
                                                                            false
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <div className="relative flex items-center justify-between w-full h-full px-6 ">
                                                                    <div
                                                                        className={`${
                                                                            active ===
                                                                            menu?.key
                                                                                ? 'text-cobalt-100'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {menu
                                                                            ?.children
                                                                            ?.length >
                                                                        0 ? (
                                                                            <div
                                                                                className={`${
                                                                                    active ===
                                                                                    menu?.key
                                                                                        ? 'text-cobalt-100'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    menu?.label
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={`${
                                                                                    active ===
                                                                                    menu?.key
                                                                                        ? 'text-cobalt-100'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        menu?.path
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        menu?.label
                                                                                    }
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {menu
                                                                        ?.children
                                                                        ?.length >
                                                                    0 ? (
                                                                        active ===
                                                                        menu?.key ? (
                                                                            <i className=" cp-icon-arraySmallLeft text-9 leading-16 rotate-90 transform text-cobalt-100  font-700  mr-[2px]" />
                                                                        ) : (
                                                                            <i className=" cp-icon-arraySmallLeft text-9 leading-16 -rotate-90 transform  font-700 mb-[2px]" />
                                                                        )
                                                                    ) : null}
                                                                </div>
                                                                <Transition
                                                                    ref={ref}
                                                                    show={
                                                                        active ===
                                                                        menu?.key
                                                                    }
                                                                    enter="transition ease-in transform duration-300"
                                                                    leave="transition ease-out transform duration-300"
                                                                    enterFrom="transform opacity-0 scale-y-0"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-y-0"
                                                                    className=" origin-top w-full bg-[#F3F3F3] px-6 mt-[23px] pt-[25px] pb-[23px] space-y-[18px] "
                                                                >
                                                                    {menu
                                                                        ?.children
                                                                        ?.length >
                                                                    0 &&
                                                                    active ===
                                                                    menu?.key ? (
                                                                        <MobileDropdown
                                                                            key={
                                                                                index
                                                                            }
                                                                            menuItem={
                                                                                menu
                                                                            }
                                                                            active={
                                                                                active ===
                                                                                menu?.key
                                                                            }
                                                                            closeDropDown={() =>
                                                                                setShowMobileHeader(
                                                                                    false
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </Transition>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            {/* Hybrid mobile nav */}
                                            <div className="flex-col justify-center hidden space-y-6 lg:flex label--largeBold">
                                                {hybridHamburgerNav?.map(
                                                    (menu, index) => {
                                                        return (
                                                            <div
                                                                className="flex flex-col justify-center h-full label--largeBold"
                                                                key={index}
                                                                onClick={() => {
                                                                    if (
                                                                        menu
                                                                            ?.children
                                                                            ?.length >
                                                                        0
                                                                    ) {
                                                                        setActive(
                                                                            active ===
                                                                            menu?.key
                                                                                ? ''
                                                                                : menu?.key
                                                                        );
                                                                        setTopHeader(
                                                                            false
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <div className="relative flex items-center justify-between w-full h-full px-6 ">
                                                                    {menu
                                                                        ?.children
                                                                        ?.length >
                                                                    0 ? (
                                                                        <div
                                                                            className={`${
                                                                                active ===
                                                                                menu?.key
                                                                                    ? 'text-cobalt-100'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            {
                                                                                menu?.label
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className={`${
                                                                                active ===
                                                                                menu?.key
                                                                                    ? 'text-cobalt-100'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            <a
                                                                                href={
                                                                                    menu?.path
                                                                                }
                                                                            >
                                                                                {
                                                                                    menu?.label
                                                                                }
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                    {menu
                                                                        ?.children
                                                                        ?.length >
                                                                    0 ? (
                                                                        active ===
                                                                        menu?.key ? (
                                                                            <i className=" cp-icon-arraySmallLeft text-9 leading-16 rotate-90 transform text-cobalt-100  font-700  mr-[2px]" />
                                                                        ) : (
                                                                            <i className=" cp-icon-arraySmallLeft text-9 leading-16 -rotate-90 transform  font-700 mb-[2px]" />
                                                                        )
                                                                    ) : null}
                                                                </div>
                                                                <Transition
                                                                    ref={ref}
                                                                    show={
                                                                        active ===
                                                                        menu?.key
                                                                    }
                                                                    enter="transition ease-in transform duration-300"
                                                                    leave="transition ease-out transform duration-300"
                                                                    enterFrom="transform opacity-0 scale-y-0"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-y-0"
                                                                    className=" origin-top w-full bg-[#F3F3F3] px-6 mt-[23px] pt-[25px] pb-[23px] space-y-[18px] "
                                                                >
                                                                    {menu
                                                                        ?.children
                                                                        ?.length >
                                                                    0 &&
                                                                    active ===
                                                                    menu?.key ? (
                                                                        <MobileDropdown
                                                                            key={
                                                                                index
                                                                            }
                                                                            menuItem={
                                                                                menu
                                                                            }
                                                                            active={
                                                                                active ===
                                                                                menu?.key
                                                                            }
                                                                            closeDropDown={() =>
                                                                                setShowMobileHeader(
                                                                                    false
                                                                                )
                                                                            }
                                                                        />
                                                                    ) : null}
                                                                </Transition>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>

                                            <div
                                                className={`flex flex-col md:flex-row md:w-full space-y-4 md:space-y-0 md:items-center md:space-x-6 mt-[55px] label--bold px-6 ${
                                                    topHeader
                                                        ? 'opacity-70'
                                                        : ''
                                                }`}
                                            >
                                                {ctaMenu?.map((item, index) => {
                                                    return (
                                                        <NextNavLink
                                                            href={item?.url}
                                                            target={
                                                                item?.target
                                                            }
                                                        >
                                                            <a
                                                                className={`btn md:w-full ${
                                                                    item
                                                                        ?.acf_cta_menu
                                                                        ?.color ===
                                                                    'dark_blue'
                                                                        ? 'btn--cobalt'
                                                                        : ' btn--sky'
                                                                } pt-[15px] pb-[13px] max-h-[48px]`}
                                                                onClick={() =>
                                                                    setShowMobileHeader(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                {item?.title}
                                                            </a>
                                                        </NextNavLink>
                                                    );
                                                })}
                                            </div>
                                            <div className="flex flex-col space-y-6 mt-[56px] px-6 justify-center ">
                                                <div className="flex items-center h-full">
                                                    <Social
                                                        options={
                                                            options?.socialMediaLinks
                                                        }
                                                        color="black"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full max-w-[8.5vw] justify-end h-full">
                                <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-b border-r border-grid hover:text-sky-100">
                                    <NextNavLink
                                        href={
                                            '/search'
                                        }
                                    >
                                        <a className="tracking-widest uppercase text-12 px-[20px] py-5px leading-20 font-united font-600">
                                            <img src="/icons/searchBlk.png"/>
                                        </a>
                                    </NextNavLink>
                                </div>
                            </div>                                
                            <div
                                className={`flex w-full items-center justify-center h-full min-h-[48px]  min-w-[96px] max-w-[96px] border-b border-grid`}
                            >
                                <div className="flex flex-col">
                                    <NextNavLink href={'/my-membership'}>
                                        <i className=" cp-icon-Account text-24 leading-24" 
                                           onClick={() => {
                                            setShowMobileHeader(false)
                                        }}
                                        />
                                    </NextNavLink>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </header>
            )}
        </>
    );
}

export default TopNav;
