import React, {useRef  } from 'react';
import { Transition } from '@headlessui/react';
import NextNavLink from '../../../util/NextNavLink';

/* A single, simple Tailwind UI dropdown menu */
export function MobileDropdown({ menuItem, closeDropDown }) {
    return (
            menuItem?.children?.map((childMenuItem, index) => (
                    <>
                        <NextNavLink
                            key={`mobile-c-${index}`}
                            href={childMenuItem.url || childMenuItem.path || '#'}
                            target={childMenuItem.target}
                        >
                            <a
                                className="whitespace-nowrap flex items-center group label--bold "
                                onClick={() => closeDropDown()}
                            >
                                <span className="group-hover:text-cobalt-100">
                                    {childMenuItem.title}{' '}
                                </span>
                            </a>
                        </NextNavLink>
                        {
                            childMenuItem.children.length > 0 
                                ? childMenuItem.children.map((innerItem, iiIndex) => (
                                    <NextNavLink
                                        key={`mobile-c-${index}-${iiIndex}`}
                                        href={innerItem.url || innerItem.path || '#'}
                                        target={innerItem.target}
                                    >   
                                        <a
                                            className="whitespace-nowrap flex items-center group label--bold"
                                            onClick={() => closeDropDown()}
                                        >
                                            <span className='w-4 h-[1px] bg-cobalt-100 mr-2'/> 
                                            <span className="group-hover:text-cobalt-100">
                                                {innerItem.title}{' '}
                                            </span>
                                        </a>
                                    </NextNavLink>
                                ))
                                : <></>
                        }
                        <div className='invisible hidden self-start w-6 bg-cobalt-100 w-[24px] group-hover:w-[28px]'/>
                    </>
               
            ))
    );
}
