import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';

import NextNavLink from '../../util/NextNavLink';
import MegaMenuPanel from './MegaMenuPanel';
import { Dropdown } from '../Header/dropdown';
import { LocationContext } from '../../../util/LocationProvider';

const getMenuItems = (menuItems, parentId) => {
    let items = [];
    for (let i=0; i<menuItems?.length; i++) {
        if (menuItems[i].node.parentId == parentId) {
            items.push(menuItems[i].node);
        }
    }
    return items;
}

const isMegaMenu = (menu) => {
    return menu?.menuMeta?.menuImage?.sourceUrl?.length > 0;
}

const formatDropdownArr = (topItem, subItems) => {
    let menuItems = {
        key: topItem?.id,
        title: topItem?.label,
        path: topItem?.url,
        url: topItem?.url,
        parentId: topItem?.parentId,
        label: topItem?.label,
        acf_cta_menu: { // fix later
            fieldGroupName: 'acf_cta_menu',
            hideOnDesktop: null,
            color: null,
            __typename: 'MenuItem_AcfCtaMenu'
        },
        children: [],
    };
    
    for (var i=0; i<subItems.length; i++) {
        menuItems.children.push({
            url: subItems[i]?.url || '',
            path: subItems[i]?.url || '',
            target: '',
            title: subItems[i]?.label,
        });
    }

    return menuItems;
}

export default function MegaMenu(props) {
    let LocationManager = useContext(LocationContext);

    const [logo, setLogo] = useState('');
    const [mobileLogo, setMobileLogo] = useState('');

    const history = useRouter();
    const [active, setActive] = useState('');
 
    useEffect(() => {
        let subsite = process?.env?.NEXT_PUBLIC_SUBSITE;
        setLogo(LocationManager.getLogo(subsite, false, 'svg'));
        setMobileLogo(LocationManager.getLogo(subsite, true, 'svg'));

    }, [
        LocationManager, 
        setLogo, 
        setMobileLogo, 
        LocationManager.sportsLocation, 
        LocationManager.eventsLocation
    ]);
       
    //Set menu open state
    const [open, setOpen] = useState({});
    const toggleMenuOpen = (menuId) => {
        const openState = open[menuId];
        setOpen(prevState => ({
            ...prevState,
            [menuId]: !openState
        }));
    };

    const items = props?.mega?.data?.menu?.menuItems?.edges;
    const top = getMenuItems(items);

    const subsite = process?.env?.NEXT_PUBLIC_SUBSITE || 'fitness';

    const [showMenu, setShowMenu] = useState(true); // Hide on scroll.

    return (<>
        {' '}
        <nav 
            component="MegaMenu"
            className={` w-full lg:block hidden  transition-all duration-300 bg-white fixed z-[98] ${showMenu ? 'pt-12' : 'pt-0'}`}> 
            <div className="flex items-center justify-between text-black-100 xl:px-[3.333%] lg:h-[80px]   lg:max-h-[80px] border-b border-grid">
                <div role="link" tabindex="0" className="flex w-full cursor-pointer py-[15px] lg:max-w-[16.715%] justify-center lg:border-l xl:border-r border-grid h-full">
                    <img
                        onClick={() => history.push('/')}
                        src={logo}
                        alt={`chelsea piers ${subsite}`}
                        className="xl:block hidden"
                    />
                    <img
                        onClick={() => history.push('/')}
                        src={mobileLogo}
                        alt={`chelsea piers ${subsite}`}
                        className="xl:hidden mobile-logo"
                    />
                </div>
                <div className="flex w-full justify-between items-center text-center lg:pl-[20px] xl:pl-[35px] lg:pr-[20px] xl:pr-[35px] border-r border-grid h-full ">
                    {top?.map((first) => { // First, top-level of the menu.
                        if (LocationManager.hideMenu(first)) return (<></>);

                        const secondaryItems = getMenuItems(items, first?.id) || [];
      
                        return (
                            <div 
                                className="label--bold h-full flex transition-all duration-300  relative hover:border-cobalt-100 border-white border-b-[3px]"
                                key={first?.id}
                                onMouseEnter={() => setActive(first?.id) }
                                onMouseLeave={() => setActive('')}
                            >      
                                <div className=" py-[30px] space-x-[8px] h-full flex hover:text-cobalt-100  transition-all duration-300 ">
                                    {!secondaryItems.length>0 && !isMegaMenu(first) && 
                                        <>
                                            <NextNavLink
                                                
                                                href={LocationManager.localeLink(first?.url) || '#'}>
                                                <a className="">{' '}{first?.label}{' '}</a>
                                            </NextNavLink>
                                        </>
                                    }
                                    {secondaryItems?.length>0 && !isMegaMenu(first) &&
                                        <>
                                            <div className="cursor-pointer here">{' '}{first?.label}{' '}</div>
                                            <i className="transform -rotate-90 cp-icon-arraySmallLeft text-9 leading-16 font-700" />

                                        </>
                                    }
                                    {isMegaMenu(first) && 
                                        <>
                                            <div className="cursor-pointer">{' '}{first?.label}{' '}</div>
                                            <i onClick={() => toggleMenuOpen(first?.id)} className="transform -rotate-90 cp-icon-arraySmallLeft text-9 leading-16 font-700"></i>
                                        </>
                                    }

                                </div>
                                {!isMegaMenu(first) && secondaryItems.length>0 &&
                                    <Dropdown 
                                    className="z-100 relative"
                                        key={first?.id}
                                        menuItem={formatDropdownArr(first, secondaryItems)}
                                        active={active === first?.id}
                                    />
                                }
                            </div>
                        )
                    })}
                    {(LocationManager.getLocation()=='stamford') && (subsite!='events') &&
                        <a className="lg:max-w-[188px] mr-[-6px] w-full h-full  flex items-center label--bold btn btn--cobalt max-h-12" href="/become-a-member">Become A Member</a>
                    }
                    {subsite == 'fitness' &&
                        <a className="lg:max-w-[188px] mr-[-6px] w-full h-full  flex items-center label--bold btn btn--cobalt max-h-12" href="/become-a-member">Become A Member</a>
                    }

                </div>
            </div>
            {top?.map((first) => { // First, top-level of the menu.
                const secondaryItems = getMenuItems(items, first?.id) || [];
                return (
                    <>
                    {isMegaMenu(first) && // Second and Tertiary levels for the MegaMenu
                        <MegaMenuPanel 
                            menu={items}
                            topMenu={first}
                            subMenu={secondaryItems}
                            active={active === first?.id}
                        />
                    }
                    </>
                )
            })}
        </nav>
    </>);
}