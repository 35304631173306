import React, { useState, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { LocationContext } from '../../../util/LocationProvider';

const getMenuItems = (menuItems, parentId) => {
    let items = [];
    for (let i=0; i<menuItems?.length; i++) {
        if (menuItems[i].node.parentId == parentId) {
            items.push(menuItems[i].node);
        }
    }
    return items;
}

/* A single, simple Tailwind UI dropdown menu */
export default function MegaMenuPanel(props) { 
    let LocationManager = useContext(LocationContext);
    const [active, setActive] = useState(false);
    const [subMenu, setSubMenu] = useState('');
    return (<>
        {(props?.active || active) && 

        <>
            {/* CLOSE Button */}
            {/* <div onClick={() => setActive(false)} className="close-panel">
                <img src="/icons/megaClose.svg"/>
            </div> */}
            <div className="mega-wrapper" onClick={() => setActive(false)}>{/*Mega Nav Panel wrapper with tinted color on click close nav*/}
                <div 
                    component="MegaMenuPanel"
                    onMouseLeave={() => {
                            setActive(false);
                            setSubMenu('');
                        }
                    }
                    onMouseEnter={() => setActive(true)}
                    // onMouseLeave={() => setActive(false)}
                    // onMouseLeave={() => setSubMenu('')}
                    className="megaNav flex w-full relative backdrop-blur-md bg-[#F3F3F3] text-black-100 lg:px-[3.333%] "
                >                                            

                    <div className="magaNavMain flex w-full justify-center h-full lg:border-l lg:border-r border-grid px-[24px] pt-[20px] pb-[80px] lg:max-w-[16.715%]">
                        <div className="sports-cat w-full">
                            <div className="mega-nav-title pb-[20px] w-full">
                                <h3>{props?.topMenu?.label}</h3>
                            </div>
                                <ul>
                                    {props?.subMenu?.map((second) => { // Second level of the menu.
                                        if (LocationManager.hideMenu(second)) return (<></>);

                                        return (<>
                                            <li key={second?.id}> 
                                                <div className="label--bold h-full transition-all duration-300  relative hover:border-cobalt-100">
                                                    <a 
                                                        href={(second?.url ? LocationManager.localeLink(second?.url) : '#')}
                                                        className="whitespace-nowrap flex items-center space-x-3  group"
                                                        onMouseEnter={() => setSubMenu(second?.id)}
                                                        onMouseLeave={() => setSubMenu(second?.id)}
                                                        onClick={() => setSubMenu(second?.id)}
                                                    >
                                                        <div className="h-[1px] inline-block bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                            {' '}
                                                        </div>
                                                        <span className="group-hover:text-cobalt-100">{second?.label} </span>
                                                    </a> 
                                                </div>
                                            </li> 
                                        </>);
                                    })}
                                </ul>
                        </div>
                    </div>
                    <div className="subMenuWrapper w-full " onMouseLeave={() => setSubMenu('')}>
                        <CSSTransition 
                            in={((props?.active || active) && !subMenu)} // Is mouse over menu button or megamenu and no secondary item is selected yet? Show default info.
                            timeout={300} 
                            classNames="subCatMain" 
                            unmountOnExit
                        >
                            <div className="w-full subCatMain" >
                                <div className="subCatMenu flex">
                                    <div className="flex w-full px-[24px] h-auto lg:border-r border-grid pt-[107px] pb-[80px] lg:max-w-[16.715%] justify-center h-full">
                                        {props?.topMenu?.menuMetaPrimaryDescription?.primaryDescription}
                                    </div>
                                    <div className="subMedia subCat1Media pt-[80px] pb-[80px] px-[80px] flex w-[70%]">
                                        <img 
                                            src={props?.topMenu?.menuMeta?.menuImage?.sourceUrl} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                        {props?.subMenu?.map((second) => { // Second level of the menu.
                            const tertiaryItems = getMenuItems(props?.menu, second?.id) || [];
                            
                                return (<>
                                    <CSSTransition 
                                        in={(subMenu == second?.id)} 
                                        key={'sub-menu-'+second?.id}
                                        timeout={0} 
                                        classNames="subCat mega-menu-right-panel" 
                                        unmountOnExit>
                                        <div className="px-[24px] w-full subCat">
                                            <div className="subCat1Menu flex" >
                                                <div className="flex w-full px-[24px] h-auto lg:border-r border-grid pt-[107px] pb-[80px] lg:max-w-[25%] justify-center h-full">
                                                    <div className="label--bold h-full w-full transition-all duration-300  relative hover:border-cobalt-100 " >
                                                        {tertiaryItems?.map((third) => {
                                                            if (LocationManager.hideMenu(third)) return (<></>);
                                                            return (<>
                                                                <div key={third?.id}>
                                                                    <a href={LocationManager.localeLink(third?.url)} className="whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                                                        <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                                            {' '}
                                                                        </div>
                                                                        <span className="group-hover:text-cobalt-100">{third?.label} </span>
                                                                    </a>
                                                                    {/* <a href={third?.url}>{third?.label}</a>  */}
                                                                </div>
                                                            </>);
                                                        })}
                                                    </div>
                                                </div>

                                            <div className="subMedia subCat1Media pt-[80px] pb-[80px] px-[80px] w-[60%]">
                                                <div className="subImg w-4/5 mb-[40px]">
                                                    <img 
                                                        src={second?.menuMeta?.menuImage?.sourceUrl} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            </>);
                        })}
                    </div>
                </div>   
            </div>
            </>     
        }

    </>);
}