import React, { useEffect, useState, useContext } from 'react';

import Navigation from './navigation';

import MegaMenu from '../MegaMenu/MegaMenu';
import MegaMenuHeader from '../MegaMenu/MegaMenuHeader';

import { useAppContext } from '../../../util/context';
import TopNav from './topnav';
import GDPR from './GDPR';

// wow js init
const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('wow.js') : null;

export default function Header(props) {
    const { menus, options, layoutType, defaults, mega } = props;

    const subsite = process.env.NEXT_PUBLIC_SUBSITE;

    let subMenu = [],
        footerMenu = [],
        menuItems = [],
        ctaMenu = [],
        hybridHamburgerNav = [],
        megaMenu = [],
        hybridTopNav = [];
    
    if (menus['sub-menu']) {
        subMenu = menus['sub-menu'].menuItems;
    }
    if (menus['footer-menu']) {
        footerMenu = menus['footer-menu'].menuItems;
    }
    if (menus['main-menu']) {
        menuItems = menus['main-menu'].menuItems;
    }
    if (menus['cta-menu']) {
        ctaMenu = menus['cta-menu'].menuItems;
    }
    if (menus['secondary-mobile-nav']) {
        hybridTopNav = menus['secondary-mobile-nav'].menuItems;
    }
    if (menus['hybrid-hamburger-menu']) {
        hybridHamburgerNav = menus['hybrid-hamburger-menu'].menuItems;
    }

    //check window dom
    const hasWindow = typeof window !== 'undefined';
    //Sticky menu
    const [sticky, setSticky] = useState(false);

    const context = useAppContext();

    useEffect(() => {
        function afterReveal(el) {
            el.setAttribute('animated', true);
            el.classList.add('to-animate');
        }

        new WOW({
            callback: afterReveal,
            offset: 100
        }).init();

        function handleSticky() {
            var y = hasWindow ? window.scrollY : 0;

            if (y > 150) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        }

        locateHref();
        anchorScroll();

        window.addEventListener('scroll', handleSticky);
        return () => {
            window.removeEventListener('scroll', handleSticky);
        };
    }, []);

    if (typeof window !== 'undefined') {
        window.addEventListener('load', locateHref);
        window.addEventListener('load', anchorScroll);
    }

    //Smooth scroll on anchor with offset
    function anchorScroll() {
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            if (anchor.getAttribute('href').length > 2) {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();

                    let yOffset = -70;
                    let element = document.querySelector(
                        this.getAttribute('href')
                    );
                    if (
                        element === '#' ||
                        element === '#!' ||
                        !element ||
                        element === '' ||
                        element.length === 0
                    ) {
                        return;
                    }
                    let y =
                        element.getBoundingClientRect().top +
                        window.pageYOffset +
                        yOffset;
                    window.scrollTo({
                        top: y,
                        behavior: 'smooth'
                    });
                });
            }
        });
    }

    //Smooth scroll on load with offset
    function locateHref() {
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
            const currUrl = window.location.href;
            setTimeout(() => {
                if (currUrl.search('#') !== -1) {
                    if (
                        currUrl.split('#')[1] &&
                        currUrl.split('#')[1].length > 1
                    ) {
                        let yOffset = -90;
                        let target = currUrl.split('#')[1];

                        //logic to expand the schedule toggle inbetween pages
                        if (
                            target === 'schedules' &&
                            currUrl?.split('/')?.includes('locations')
                        ) {
                            context?.toggleExpand(true);
                        }

                        let element = document.getElementById(target);
                        if (element) {
                            let y =
                                element.getBoundingClientRect().top +
                                window.pageYOffset +
                                yOffset;
                            window.scrollTo({
                                top: y,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            }, 100);
        }
    }

    return (
        <>    
            {!mega && 
                <>
                    <TopNav
                        subMenu={subMenu}
                        hybridTopNav={hybridTopNav}
                        hybridHamburgerNav={hybridHamburgerNav}
                        menuItems={menuItems}
                        options={options}
                        sticky={sticky}
                        layoutType={layoutType}
                        ctaMenu={ctaMenu}
                        hideNav={context?.hideNav}
                    />
                    <Navigation
                        subMenu={subMenu}
                        hybridTopNav={hybridTopNav}
                        hybridHamburgerNav={hybridHamburgerNav}
                        menuItems={menuItems}
                        options={options}
                        sticky={sticky}
                        layoutType={layoutType}
                        ctaMenu={ctaMenu}
                        hideNav={context?.hideNav}
                    />
                </>
            }
            {mega &&
                <>
                    <MegaMenuHeader 
                        subMenu={subMenu}
                        hybridTopNav={hybridTopNav}
                        hybridHamburgerNav={hybridHamburgerNav}
                        menuItems={menuItems}
                        options={options}
                        sticky={sticky}
                        layoutType={layoutType}
                        ctaMenu={ctaMenu}
                        hideNav={context?.hideNav} 
                        mega={mega}
                    />
                    <MegaMenu
                        mega={mega} 
                        hideNav={context?.hideNav}
                    />
                </>
            }
            <GDPR content={defaults?.gdrpCookieNotice?.acf_options_GDPR} />
        </>
    );
}
