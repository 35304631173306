import React, { useState, useEffect, useContext } from 'react';
import { Social } from '../../../../blocks/acf/Social';
import NextNavLink from '../../../util/NextNavLink';
import useWindowDimensions from '../../../../util/windowDimensions';
import { LocationContext } from '../../../../util/LocationProvider';

const FooterMultiNavigation = ({ menus, options, props }) => { 


    const { height, width } = useWindowDimensions();

    let LocationManager = useContext(LocationContext);
    let location = LocationManager.getLocation();

    // SOCIAL LINKS
    let center = LocationManager.getCenter(location);
    let social = props.social;

    function getSocial(center) {
        let links = [];
        if (social?.length) {
            social.forEach(l => {
                if (l?.location == location) {
                    links.push({
                        platform: l?.socialPlatform,
                        link: l?.link,
                    });
                }
            })
        }
        return links;
    }
    // END SOCIAL LINKS

    let subsite = process?.env?.NEXT_PUBLIC_SUBSITE ? process?.env?.NEXT_PUBLIC_SUBSITE : location;
    //const [location, setLocation] = useState(LocationManager.getLocation());
    const [logo, setLogo] = useState('');
    // const [mobileLogo, setMobileLogo] = useState('');
    
    useEffect(() => {
        let subsite = process?.env?.NEXT_PUBLIC_SUBSITE;
        setLogo(LocationManager.getLogo(subsite, false));
        //setMobileLogo(LocationManager.getLogo(subsite, true, 'svg'));

    }, [
        LocationManager, 
        setLogo, 
        //setMobileLogo, 
        LocationManager.sportsLocation, 
        LocationManager.eventsLocation
    ]);
    return (
        <>
        {/* Chelsea Footer */}
        <div className="bg-black-100 flex relative h-full flex-col  z-[20]">
            <div className="xl:w-[1px] left-[3.333%] absolute h-full bg-grid"></div>
            <div className="xl:w-[1px]  absolute  h-full left-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  absolute  h-full right-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px] right-[3.333%] absolute h-full bg-grid"></div>

            <div className="absolute flex justify-between w-full h-full px-3 xl:hidden">
                <div className="w-[1px] bg-grid"></div>
                <div className="w-[1px]  bg-grid"></div>
            </div>
            <div className="px-3 xl:px-[3.333%] flex justify-between relative z-[1]">
                <div className="flex xl:flex-row flex-col   w-full text-white  xl:h-[672px] ">
                    <div className="flex items-center justify-center w-full h-full border-b border-grid xl:border-none pt-14 pb-11 xl:py-0">
                        <div className="flex items-center justify-center w-full h-full px-6">
                            <div className="flex flex-col items-center justify-center w-full h-full">
                                <div className="flex wow fadeInUp">
                                    {/* <img
                                        src={
                                            options?.mainMenuOptions?.footer
                                                ?.footerLogo?.mediaItemUrl
                                        }
                                    /> */}
                                    <img
                                        //onClick={() => history.push('/')}
                                        src={logo}
                                        //src="/logos/chelseaWht.svg"
                                        //width="128"
                                        // height="41.5"
                                        alt={`chelsea piers ${subsite}`}
                                    />
                                </div>
                                {/* <div className="mt-8 text-center lead--Regular wow fadeInUp">
                                {location == 'stamford' &&
                                    <a href="mailto:ct-info@chelseapiers.com">ct-info@chelseapiers.com</a>
                                }
                                {location == 'chelsea' &&
                                    <a href="mailto:info@chelseapiers.com">info@chelseapiers.com</a>
                                }
                                </div> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex items-center justify-center w-full h-full ">
                        <div className="flex flex-col items-center w-full h-full md:flex-row xl:flex-col ">
                            {menus?.slice(0, 1)?.map((item, index) => {
                                return (
                                    <>
                                    <div
                                        className={`w-full h-full flex  justify-center border-b  border-grid xl:min-h-[304px] xl:max-h-[304px] ${
                                            index === 0
                                                ? 'md:border-r border-grid xl:border-b xl:border-r-0 '
                                                : ''
                                        }`}
                                        key={index}
                                    >
                                        <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                            <p className="label--largeBold text-sky-100 wow fadeInUp  to-animate"  animated="true">
                                                Information
                                            </p>
                                            {LocationManager.getLocation() == 'chelsea' &&
                                            <div className="flex flex-col space-y-2 text-center ">
                                                <a id="cLoc" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/locations", "_self")}
                                                    //href="https://sports.chelseapiers.com/new-york/chelsea/locations"  
                                                    animated="true">
                                                    Locations
                                                </a>
                                                {/* <a id="cSS" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    href="/safety"  animated="true">
                                                    Safety Standards
                                                </a> */}
                                                <a id="cP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com" 
                                                    target="_blank" animated="true">
                                                    Playbook
                                                </a>
                                                <a id="cNP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com/" 
                                                    target="_blank"  animated="true">
                                                    News + Press
                                                </a>
                                                <a id="cVU" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/visit-us", "_self")}
                                                    //href="https://sports.chelseapiers.com/new-york/chelsea/visit-us"  
                                                    animated="true">
                                                    Visit Us
                                                </a>
                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'stamford' &&
                                            <div className="flex flex-col space-y-2 text-center ">
                                                <a id="sL" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/locations", "_self")}
                                                    animated="true">
                                                    Locations
                                                </a>
                                                {/* <a id="sSS" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    href="/safety"  animated="true">
                                                    Safety Standards
                                                </a> */}
                                                <a id="sP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com" 
                                                    target="_blank" animated="true">
                                                    Playbook
                                                </a>
                                                <a id="sNP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com/" 
                                                    target="_blank" animated="true">
                                                    News + Press
                                                </a>
                                                <a id="sVU" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/visit-us", "_self")}
                                                    //href="https://sports.chelseapiers.com/connecticut/stamford/visit-us"  
                                                    animated="true">
                                                    Visit Us
                                                </a>
                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'brooklyn' &&
                                            <div className="flex flex-col space-y-2 text-center ">
                                                <a id="sL" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/brooklyn/locations", "_self")}
                                                    animated="true">
                                                    Locations
                                                </a>
                                                {/* <a id="sSS" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    href="/safety"  animated="true">
                                                    Safety Standards
                                                </a> */}
                                                <a id="sP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com" 
                                                    target="_blank" animated="true">
                                                    Playbook
                                                </a>
                                                <a id="sNP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://playbook.chelseapiers.com", "_blank")}
                                                    //href="https://playbook.chelseapiers.com/" 
                                                    target="_blank" animated="true">
                                                    News + Press
                                                </a>
                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/brooklyn/visit-us", "_self")}
                                                    //href="https://sports.chelseapiers.com/new-york/brooklyn/visit-us" 
                                                    animated="true">
                                                    Getting Here
                                                </a>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    
                                    <div id="comp" className="w-full h-full flex  justify-center  border-b xl:border-none border-grid xl:min-h-[368px] xl:max-h-[368px]">
                                        <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                            <p className="label--largeBold text-sky-100 wow fadeInUp  to-animate"  animated="true">
                                                Company
                                            </p>
                                            {LocationManager.getLocation() == 'chelsea' &&
                                            <div className="flex flex-col space-y-2 text-center">
                                                <a id="cACP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/sponsorship-partnership", "_self")}
                                                    //href="https://www.chelseapiers.com/company/about/"
                                                    target="_blank" animated="true">
                                                    About Chelsea Piers
                                                </a>
                                                {/* <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    href="/sports/employment"  animated="true">
                                                    Chelseea Piers Leadership
                                                </a> */}
                                                <a id="cJ" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/employment", "_self")}
                                                    //href="https://sports.chelseapiers.com/new-york/chelsea/employment"
                                                    animated="true" 
                                                    >
                                                    Jobs at Chelsea Piers
                                                </a>
                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://www.chelseapiers.com/cpsf/overview/", "_blank")}
                                                    //href="https://www.chelseapiers.com/cpsf/overview/"
                                                     target="_blank"  animated="true" >
                                                    Giving Back
                                                </a>
                                                <a id="cSP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                     onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/sponsorship-partnership", "_self")}
                                                    //href="https://www.chelseapiers.com/also-at-the-piers/sponsorship/"
                                                    target="_blank" animated="true">
                                                    Sponsorships/Partnership
                                                </a>
                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'stamford' &&
                                            <div id="sAbout" className="flex flex-col space-y-2 text-center">
                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://www.chelseapiers.com/company/about/", "_blank")}
                                                    target="_blank" animated="true">
                                                    About Chelsea Piers
                                                </a>
                                                <a id="sJC" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/employment", "_self")}
                                                    animated="true">
                                                    Jobs at Chelsea Piers
                                                </a>
                                                <a id="sGB" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://www.chelseapiersct.com/company/giving-back/", "_blank")}
                                                    // href='https://www.chelseapiersct.com/company/giving-back/'
                                                    target="_blank"  animated="true" >
                                                    Giving Back
                                                </a>
                                                <a id="sSP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/sponsorship-partnership", "_self")}
                                                    //href="https://www.chelseapiersct.com/company/official-sponsors/" 
                                                    target="_blank"  animated="true">
                                                    Sponsorships/Partnership
                                                </a>
                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'brooklyn' &&
                                            <div className="flex flex-col space-y-2 text-center">
                                                <a id="cACP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://www.chelseapiers.com/company/about/", "_blank")}
                                                    //href="https://www.chelseapiers.com/company/about/"
                                                    target="_blank" animated="true">
                                                    About Chelsea Piers
                                                </a>
                                                {/* <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    href="/sports/employment"  animated="true">
                                                    Chelseea Piers Leadership
                                                </a> */}
                                                <a id="cJ" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/brooklyn/employment", "_self")}
                                                    //href="https://sports.chelseapiers.com/new-york/brooklyn/employment"
                                                    animated="true" 
                                                    >
                                                    Jobs at Chelsea Piers
                                                </a>
                                                <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://www.chelseapiers.com/cpsf/overview/", "_blank")}
                                                    //href="https://www.chelseapiers.com/cpsf/overview/"
                                                     target="_blank"  animated="true" >
                                                    Giving Back
                                                </a>
                                                <a id="cSP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/brooklyn/sponsorship-partnership", "_self")}
                                                    //href="https://www.chelseapiers.com/also-at-the-piers/sponsorship/"
                                                    target="_blank" animated="true">
                                                    Sponsorships/Partnership
                                                </a>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    </>
                                );
                            })}
                        
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full h-full ">
                        <div className="flex flex-col items-center w-full h-full md:flex-row xl:flex-col ">
                            {menus
                                ?.slice(
                                    width < 1340 ? 2 : 1,
                                    width < 1340 ? 3 : 2
                                )
                                ?.map((item, index) => {
                                    return (
                                        <>
                                        <div
                                            className={`w-full h-full flex justify-center px-6 border-b border-grid  xl:min-h-[304px] xl:max-h-[304px] ${
                                                index === 0
                                                    ? 'md:border-r md:border-b-0 border-grid  xl:border-b xl:border-r-0'
                                                    : ''
                                            }`}
                                            key={index}
                                        >
                                            <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                                <div className="flex flex-col space-y-2 text-center">
                                                    <p className="label--largeBold text-sky-100 wow fadeInUp  to-animate" animated="true" >
                                                        Connect
                                                    </p>
                                                    {LocationManager.getLocation() == 'stamford' &&
                                                    <div className="flex flex-col space-y-2 text-center">
                                                        <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                            onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/contact-us", "_self")}
                                                            //href="https://sports.chelseapiers.com/connecticut/stamford/contact-us" 
                                                            animated="true" > 
                                                            Contact Us
                                                        </a>
                                                        <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                            href="https://sports.chelseapiers.com/connecticut/stamford/membership#app" animated="true" > 
                                                            Download Our App
                                                        </a>
                                                    </div>
                                                    }
                                                    {LocationManager.getLocation() == 'chelsea' &&
                                                    <div className="flex flex-col space-y-2 text-center">
                                                        <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                            onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/contact-us", "_self")} animated="true" > 
                                                            Contact Us
                                                        </a>
                                                    </div>
                                                    }
                                                    {LocationManager.getLocation() == 'brooklyn' &&
                                                    <div className="flex flex-col space-y-2 text-center">
                                                        <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                            onClick={()=> window.open(" https://sports.chelseapiers.com/new-york/brooklyn/contact-us", "_self")} animated="true" > 
                                                            Contact Us
                                                        </a>
                                                    </div>
                                                    }

                                                    {width < 1340 ? null : (
                                                        <>
                                                        <div className="opacity-50 h-[24px]">
                                                            <p className=" lead--Regular mt-[-4px]">__</p>
                                                        </div>
                                                        {/* NEW SOCIAL */}
                                                        <div className="flex w-full items-center justify-between space-x-[18px]">
                                                            {getSocial(center)?.map(item => { 
                                                                return (
                                                                    <a className="cursor-pointer "  
                                                                        onClick={()=> window.open(item?.link, "_blank")}
                                                                        target="_blank">
                                                                    <i className={`text-white hover:text-sky-100 text-15 leading-15  cp-icon-${item?.platform}`}></i>
                                                                    </a>

                                                                );
                                                            })}
                                                        </div>
                                                         {/* END NEW SOCIAL */}
                                                        {/* {LocationManager.getLocation() == 'stamford' &&
                                                        <div className="flex w-full items-center justify-between space-x-[18px] " id="">
                                                            <a id="sIN" className=" cursor-pointer "  
                                                                onClick={()=> window.open("https://www.instagram.com/chelseapiersct/", "_blank")}
                                                                //href="https://www.instagram.com/chelseapiersct/" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-instagram "></i>
                                                            </a>
                                                            <a id="sFB" className="cursor-pointer" 
                                                                onClick={()=> window.open("https://www.facebook.com/ChelseaPiersCT/", "_blank")}
                                                                //href="https://www.facebook.com/ChelseaPiersCT/" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-facebook "></i>
                                                            </a>
                                                            <a id="sTW" className="cursor-pointer"  
                                                                onClick={()=> window.open("https://twitter.com/ChelseaPiersCT", "_blank")}
                                                                //href="https://twitter.com/ChelseaPiersCT" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-twitter "></i>
                                                            </a>
                                                            <a id="sLI" className="cursor-pointer" 
                                                                onClick={()=> window.open("https://www.linkedin.com/company/chelsea-piers-connecticut", "_blank")}
                                                                //href="https://www.linkedin.com/company/chelsea-piers-connecticut" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-linkedIn "></i>
                                                            </a>
                                                            <a id="sYT" className="cursor-pointer"
                                                                onClick={()=> window.open("https://www.youtube.com/c/Chelseapiersct/videos", "_blank")}
                                                                //href="https://www.youtube.com/c/Chelseapiersct/videos"
                                                                target="_blank"
                                                            >
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-youtube "></i>
                                                            </a>
                                                        </div>
                                                        }
                                                        {LocationManager.getLocation() == 'chelsea' &&
                                                        <div className="flex w-full items-center justify-between space-x-[18px] " id="">
                                                            <a id="cIn" className="cursor-pointer" 
                                                                onClick={()=> window.open("https://www.instagram.com/ChelseaPiersNYC/", "_blank")}
                                                                //href="https://www.instagram.com/ChelseaPiersNYC/" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-instagram "></i>
                                                            </a>
                                                            <a id="cFB" className="cursor-pointer" 
                                                                onClick={()=> window.open("https://www.facebook.com/FieldHouseAtChelseaPiers", "_blank")} 
                                                                //href="https://www.facebook.com/FieldHouseAtChelseaPiers" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-facebook "></i>
                                                            </a>
                                                            <a id="cTw" className="cursor-pointer"  
                                                                onClick={()=> window.open("https://twitter.com/ChelseaPiersNYC", "_blank")} 
                                                                //href="https://twitter.com/ChelseaPiersNYC" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-twitter "></i>
                                                            </a>
                                                            <a id="cLI" className="cursor-pointer"
                                                                onClick={()=> window.open("https://www.linkedin.com/company/chelsea-piers", "_blank")}
                                                                //href="https://www.linkedin.com/company/chelsea-piers" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-linkedIn "></i>
                                                            </a>
                                                            <a id="cYT" className="cursor-pointer" 
                                                                onClick={()=> window.open("https://www.youtube.com/@ChelseaPiersNYC/videos", "_blank")}
                                                                //href="https://www.youtube.com/@ChelseaPiersNYC/videos" 
                                                                target="_blank">
                                                                <i className=" text-white hover:text-sky-100 text-15 leading-15  cp-icon-youtube "></i>
                                                            </a>
                                                        </div>
                                                        } */}
                                                        {LocationManager.getLocation() == 'chelsea' &&
                                                        <span>&nbsp;</span>
                                                        }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    );
                                })}

                            {menus?.slice(3, 4)?.map((item, index) => {
                                return (
                                    <>  
                                    <div
                                        className={`w-full h-full flex justify-center  px-6 border-grid xl:min-h-[368px] xl:max-h-[368px]`}
                                        key={index}
                                    >
                                        <div className="flex flex-col items-center justify-start py-12 space-y-6 xl:py-16 xl:space-y-8">
                                            <p className="label--largeBold text-sky-100 wow fadeInUp  to-animate" animated="true" >
                                                More At Chelsea Piers
                                            </p>
                                            {LocationManager.getLocation() == 'chelsea' &&
                                            <div className="flex flex-col space-y-2 text-center">
                                                {/* <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                href="https://www.chelseapiers.com/fh/" animated="true" > Chelsea Piers Sports</a> */}
                                                <a id="ccP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                href="https://fitness.chelseapiers.com/" animated="true" target="_blank"> Chelsea Piers Fitness</a>
                                                <a id="cCE" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://events.chelseapiers.com/new-york/chelsea/welcome", "_self")}
                                                    //href="https://events.chelseapiers.com/new-york/chelsea/welcome" 
                                                animated="true" target="_blank"> Chelsea Piers Events</a>
                                                <a id="cCM" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate"
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/marina", "_self")} 
                                                    // href="/new-york/chelsea/marina"  
                                                animated="true">Chelsea Piers Marina</a>
                                                <a id="cAMM" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/sports/amenities", "_self")}
                                                    //href="/new-york/chelsea/sports/amenities"  
                                                    animated="true">On-Site Amenities</a>
                                                <a id="cGC" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate"
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/new-york/chelsea/gift-cards", "_self")} 
                                                    //href="/new-york/chelsea/gift-cards"  
                                                    animated="true">Gift Cards</a>
                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'stamford' &&
                                            <div className="flex flex-col space-y-2 text-center">
                                                <a id="sCPF" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                    onClick={()=> window.open("https://fitness.chelseapiers.com/", "_self")}
                                                    //href="https://fitness.chelseapiers.com/" 
                                                    animated="true" target="_blank"> Chelsea Piers Fitness</a>
                                                <a id="sCPE" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://events.chelseapiers.com/connecticut/stamford/welcome", "_self")}
                                                    //href="https://events.chelseapiers.com/connecticut/stamford/welcome" 
                                                    animated="true" target="_blank"> Chelsea Piers Events</a>
                                                <a id="sAMM" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate"
                                                    onClick={()=> window.open("https://sports.chelseapiers.com/connecticut/stamford/sports/amenities", "_self")} 
                                                    //href="/connecticut/stamford/sports/amenities"  
                                                    animated="true">On-Site Amenities</a>

                                            </div>
                                            }
                                            {LocationManager.getLocation() == 'brooklyn' &&
                                            <div className="flex flex-col space-y-2 text-center">
                                                {/* <a className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                href="https://www.chelseapiers.com/fh/" animated="true" > Chelsea Piers Sports</a> */}
                                                <a id="ccP" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" 
                                                href="https://fitness.chelseapiers.com/" animated="true" target="_blank"> Chelsea Piers Fitness</a>
                                                <a id="cCE" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate cursor-pointer" 
                                                    onClick={()=> window.open("https://events.chelseapiers.com/new-york/chelsea/welcome", "_self")}
                                                    //href="https://events.chelseapiers.com/new-york/chelsea/welcome" 
                                                animated="true" target="_blank"> Chelsea Piers Events</a>
                                                {/* <a id="cCM" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" href="/new-york/chelsea/marina"  animated="true">Chelsea Piers Marina</a>
                                                <a id="cAMM" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" href="/new-york/chelsea/sports/amenities"  animated="true">On-Site Amenities</a>
                                                <a id="cGC" className="lead--Regular hover:text-sky-100 wow fadeInUp  to-animate" href="/new-york/chelsea/gift-cards"  animated="true">Gift Cards</a> */}

                                            </div>
                                            }
                                        </div>
                                    </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* END Chelsea Footer*/}
        
        </>
    );
};

export default FooterMultiNavigation;
