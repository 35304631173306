import  { useContext } from 'react'
import NextNavLink from '../../components/util/NextNavLink';

export const Social = ({
    options,
    color,
    spaceBetween,
    hover,
    anchor = '',
    className = ''
}) => {

    const iconFinder = (item) => {
        switch (item?.social) {
            case 'instagram':
                return (
                    <NextNavLink href={item?.link} target={'_blank'}>
                        <a>
                            <i
                                key={item?.social}
                                className={` ${
                                    color === 'black'
                                        ? 'text-black-100'
                                        : 'text-white'
                                } ${
                                    hover ? hover : ''
                                } text-15 leading-15  cp-icon-instagram `}
                            ></i>
                        </a>
                    </NextNavLink>
                );
                break;
            case 'linkedin':
                return (
                    <NextNavLink href={item?.link} target={'_blank'}>
                        <a>
                            <i
                                key={item?.social}
                                className={` ${
                                    color === 'black'
                                        ? 'text-black-100'
                                        : 'text-white'
                                } ${
                                    hover ? hover : ''
                                } text-15 leading-15  cp-icon-linkedIn `}
                            ></i>
                        </a>
                    </NextNavLink>
                );
                break;
            case 'facebook':
                return (
                    <NextNavLink href={item?.link} target={'_blank'}>
                        <a>
                            <i
                                key={item?.social}
                                className={` ${
                                    color === 'black'
                                        ? 'text-black-100'
                                        : 'text-white'
                                } ${
                                    hover ? hover : ''
                                } text-15 leading-15  cp-icon-facebook `}
                            ></i>
                        </a>
                    </NextNavLink>
                );
                break;
            case 'youtube':
                return (
                    <NextNavLink href={item?.link} target={'_blank'}>
                        <a>
                            <i
                                key={item?.social}
                                className={` ${
                                    color === 'black'
                                        ? 'text-black-100'
                                        : 'text-white'
                                } ${
                                    hover ? hover : ''
                                } text-15 leading-15  cp-icon-youtube `}
                            ></i>
                        </a>
                    </NextNavLink>
                );
                break;

            case 'twitter':
                return (
                    <NextNavLink href={item?.link} target={'_blank'}>
                        <a>
                            <i
                                key={item?.social}
                                className={` ${
                                    color === 'black'
                                        ? 'text-black-100'
                                        : 'text-white'
                                } ${
                                    hover ? hover : ''
                                } text-15 leading-15  cp-icon-twitter `}
                            ></i>
                        </a>
                    </NextNavLink>
                );
                break;

            default:
                break;
        }
    };

    return (
        <div
            className={`flex w-full items-center justify-between ${
                spaceBetween ? spaceBetween : ''
            } ${className}`}
            id={anchor}
        >
            {options?.map((item) => {
                return iconFinder(item);
            })}
        </div>
    );
};
