import React, { useEffect, useRef, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../util/NextNavLink';
import { Dropdown } from '../Header/dropdown';
import { MobileDropdown } from '../Header/dropdown/MobileDropdown';
import { Social } from '../../../blocks/acf/Social';

import { LocationContext } from '../../../util/LocationProvider';


const getMenuItems = (menuItems, parentId) => {
    let items = [];
    for (let i=0; i<menuItems?.length; i++) {
        if (menuItems[i].node.parentId == parentId) {
            items.push(menuItems[i].node);
        }
    }
    return items;
}

const isMegaMenu = (menu) => {
    return menu?.menuMeta?.menuImage?.sourceUrl?.length;
}

const siteTitle = (subsite) => {
    return subsite[0].toUpperCase() + subsite.slice(1);
}

function MegaMenuHeader({
                    menuItems,
                    subMenu,
                    hybridTopNav,
                    hybridHamburgerNav,
                    sticky,
                    ctaMenu,
                    options,
                    variant,
                    headerTheme = '',
                    hideNav,
                    mega
                }) {

    const LocationManager = useContext(LocationContext);

    const [logo, setLogo] = useState('');
    const [mobileLogo, setMobileLogo] = useState('');

    useEffect(() => {
        let subsite = process?.env?.NEXT_PUBLIC_SUBSITE;
        setLogo(LocationManager.getLogo(subsite, false, 'svg'));
        setMobileLogo(LocationManager.getLogo(subsite, true, 'svg'));

    }, [
        LocationManager, 
        setLogo, 
        setMobileLogo,
        LocationManager?.sportsLocation,
        LocationManager?.eventsLocation
    ]);
       

    //For mobile menu
    const [showMenu, setShowMenu] = useState(true);
    const [CTAOpen, setCTAOpen] = useState(false);
    const [showMobileHeader, setShowMobileHeader] = useState(false);
    const [topHeader, setTopHeader] = useState(false);
    const [active, setActive] = useState(false);
    const ref = useRef();

    const items = mega?.data?.menu?.menuItems?.edges;
    const top = getMenuItems(items);
    const subsite = process?.env?.NEXT_PUBLIC_SUBSITE || 'fitness';
    const [location, setLocation] = useState(LocationManager.getLocation());
    const [sportsLocationLabel, setSportsLocationLabel] = useState(LocationManager?.sportsLabel);
    const [eventsLocationLabel, setEventsLocationLabel] = useState(LocationManager?.eventsLabel);

    useEffect(() => {
        setSportsLocationLabel(LocationManager?.sportsLabel);
        setEventsLocationLabel(LocationManager?.eventsLabel);
    }, [
        setSportsLocationLabel,
        setEventsLocationLabel,
        LocationManager.sportsLabel,
        LocationManager.eventsLabel,
    ])

    const setSportsLocation = (location) => {
        LocationManager.dropdownLocation('sports', location);
        setHiddenSports(true);
    };
    const setEventsLocation = (location) => {
        LocationManager.dropdownLocation('events', location);
        setHiddenEvents(true);
    };
    const isLocationActive = (site, location) => {
        switch(site) {
            case 'events': return (location == LocationManager?.eventsLocation ? 'active' : '');
            case 'sports': return (location == LocationManager?.sportsLocation ? 'active' : '');
            default: break;
        }
    };
    const getSiteLabel = (site) => {
        switch(site) {
            case 'events': return eventsLocationLabel;
            case 'sports': return sportsLocationLabel;
            default: break;
        }
    };

    const history = useRouter();

    const [hiddenSports, setHiddenSports] = useState(true);
    const [hiddenEvents, setHiddenEvents] = useState(true);
    const buttonActiveClassSports = (state) => {
        return (!hiddenSports ? ' active ' : '');
    }
    const buttonActiveClassEvents = (state) => {
        return (!hiddenEvents ? ' active ' : '');
    }

    const getMembershipLink = (locale='') => {
        switch (locale) {
            case 'brooklyn': return 'https://member.daysmartrecreation.com/#/online/chelsea/login'; break;
            case 'chelsea':  return 'https://member.daysmartrecreation.com/#/online/chelsea/login'; break;
            case 'stamford': return 'https://www.chelseapiersct.com/member-site/my-membership/'; break;
            default: return '/my-membership'; break;
        }
    }

    return (
        <>
            {!hideNav && (
                <header
                    component="MegaMenuHeader"
                    className={`fixed   left-0 w-full  z-[99] transition-all duration-300 bg-black-100 ${
                        showMenu ? 'top-0' : 'lg:-top-14 top-0 top-hidden'
                    } `}
                >
                    <div className={process?.env?.NEXT_PUBLIC_SUBSITE + ' ' + 'hidden lg:flex items-center justify-between overflow-hidden h-[48px] max-h-[48px] px-[3.333%]'}>
                        <div className="sports-select items-center  w-full max-w-[36vw] h-full justify-items-start">
                            <div className="dropdown flex border-l border-r border-grid">
                                <button className={`dropbtn selected-location-sports` + buttonActiveClassSports(hiddenSports)} onClick={() => setHiddenSports(!hiddenSports)}>
                                    Sports &mdash; {sportsLocationLabel}
                                    <svg className="-mr-1 ml-2 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                {!hiddenSports ? 
                                    <div className="dropdown-content">
                                        <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                            <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                {' '}
                                            </div>
                                            <span onClick={() => setSportsLocation('stamford')} className={`set-location ` + isLocationActive('sports', 'stamford')}>Sports &mdash; Stamford, CT</span>
                                        </div>
                                        <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                            <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                {' '}
                                            </div>
                                            <span onClick={() => setSportsLocation('chelsea')} className={`group-hover:text-cobalt-100`+ ` ` + `set-location ` + isLocationActive('sports', 'chelsea')}>Sports &mdash; Chelsea, NY</span>
                                        </div>
                                    <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                        <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                            {' '}
                                        </div>
                                        <span onClick={() => setSportsLocation('brooklyn')} className={`set-location ` + isLocationActive('sports', 'brooklyn')}>Sports &mdash; Brooklyn, NY</span>
                                    </div>
                                        
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="border-r block border-grid items-center text-center  w-full max-w-[36vw] h-full justify-items-start">
                            <div className={`block desktop-nav-fitness ` + subsite}>
                                <a href="https://fitness.chelseapiers.com/" className=" block w-full text-12 text-white leading-20 font-united font-600 tracking-widest uppercase text-opacity-70  lg:max-w-[23.25vw] border-grid  lg:py-4">
                                    Fitness
                                </a>
                            </div>
                        </div>
                        <div className="events-select items-center w-full max-w-[36vw] h-full justify-items-start">
                            <div className="dropdown flex border-r border-grid">
                                <button className={`dropbtn selected-location-events ` + buttonActiveClassEvents(hiddenEvents)}  onClick={() => setHiddenEvents(!hiddenEvents)}>
                                    Events &mdash; {eventsLocationLabel}
                                    <svg className="-mr-1 ml-2 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                {!hiddenEvents ? 
                                    <div className="dropdown-content">
                                        <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                            <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                {' '}
                                            </div>
                                            <span onClick={() => setEventsLocation('stamford')} className="set-location">Events &mdash; Stamford, CT</span>
                                        </div>
                                        <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                            <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                {' '}
                                            </div>
                                            <span onClick={() => setEventsLocation('chelsea')} className="set-location">Events &mdash; Chelsea, NY</span>
                                        </div>
                                        <div className="px-[15px] whitespace-nowrap flex items-center space-x-3  group mb-[10px]">
                                            <div className="h-[1px] bg-cobalt-100 w-0 group-hover:w-6 transition-all duration-300">
                                                {' '}
                                            </div>
                                            <span onClick={() => setEventsLocation('brooklyn')} className="set-location">Events &mdash; Brooklyn, NY</span>
                                        </div>
                                </div>
                                : null}
                            </div>
                        </div>
                        <div className="flex w-full max-w-[8.5vw] justify-end h-full">

                        </div>
                        {LocationManager.getLocation()=='chelsea' && (subsite!='events') &&
                        <div className="flex w-full max-w-[14.5vw] justify-end h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <i className=" cp-icon-Account text-24 leading-24 mobile" />
                                <NextNavLink
                                    href={
                                        'https://member.daysmartrecreation.com/#/online/chelsea/login'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 leading-20 font-united font-600">
                                        My Account
                                    </a>
                                </NextNavLink>
                            </div>
                        </div>
                        }
                        {LocationManager.getLocation() == 'brooklyn' && (subsite!='events') &&
                        <div className="flex w-full max-w-[14.5vw] justify-end h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <i className=" cp-icon-Account text-24 leading-24" />
                                <NextNavLink
                                    href={
                                        'https://member.daysmartrecreation.com/#/online/chelsea/login'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 leading-20 font-united font-600">My Account</a>
                                </NextNavLink>
                            </div>
                        </div>
                        }
                        {(LocationManager.getLocation()=='stamford') && (subsite!='events') &&
                        <div className="flex w-full max-w-[14.5vw] justify-end h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <i className=" cp-icon-Account text-24 leading-24" />

                                <NextNavLink
                                    id="myacc2"
                                    target="_blank"
                                    href={
                                        'https://www.chelseapiersct.com/member-site/my-membership/'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 leading-20 font-united font-600">
                                        My Account
                                    </a>
                                </NextNavLink>
                            </div>
                        </div>
                        }
                        {subsite == 'fitness' &&
                        <div className="flex w-full max-w-[14.5vw] justify-end h-full">
                            <div className="flex items-center justify-center w-full h-full my-auto space-x-4 text-center text-white border-l border-r border-grid hover:text-sky-100">
                                <i className=" cp-icon-Account text-24 leading-24" />

                                <NextNavLink
                                    href={
                                        'https://fitness.chelseapiers.com/my-membership'
                                    }
                                >
                                    <a className="tracking-widest uppercase text-12 leading-20 font-united font-600">
                                        My Account
                                    </a>
                                </NextNavLink>
                            </div>
                        </div>
                        }
                    </div>

                    <div className=" lg:hidden flex items-center justify-between w-full  h-[64px] max-h-[64px] border-b border-grid   bg-white">
                        <div className="flex items-center justify-items-start w-full border-r border-grid   h-full min-w-[264px] lg:max-w-[16.715%]">
                            <div className="flex items-center w-full h-full px-6">
                            <img
                                onClick={() => history.push('/')}
                                src={mobileLogo}
                                alt={`chelsea piers ${subsite}`}
                            />
                            </div>
                        </div>

                        <div className="flex w-full items-center justify-center h-full min-w-[100px] max-w-[100px]">
                            <div
                                className={`${
                                    !showMobileHeader ? 'mt-2.5' : ''
                                }`}
                                onClick={() =>
                                    setShowMobileHeader(!showMobileHeader)
                                }
                            >
                                {showMobileHeader ? (
                                    <img src="/images/closeButton.png" alt="" />
                                ) : (
                                    <i className=" cp-icon-Menu text-black-100 text-30" />
                                )}
                            </div>
                        </div>
                    </div>
                    <Transition
                        className=""
                        show={showMobileHeader}
                        enter="transition ease-out duration-100"
                        enterFrom=" opacity-0 scale-100"
                        enterTo=" opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom=" opacity-100 scale-100"
                        leaveTo=" opacity-0 scale-100"
                    >
                        <div className="lg:hidden bg-white   flex justify-between h-full h-[48px] relative ">
                            <div className="flex flex-col relative   w-full min-w-[264px]">
                                <div
                                    className="flex justify-between items-center h-full  min-h-[48px] border-r border-grid border-b px-6 label--bold "
                                    onClick={() => {
                                        setTopHeader(!topHeader);
                                        setActive('');
                                    }}
                                >

                                    <div className="selected-location-mobile text-cobalt-100">{siteTitle(subsite)} &mdash; {getSiteLabel(subsite)}</div>

                                    {topHeader ? (
                                        <i className="inline-block transform rotate-90 cp-icon-arraySmallLeft text-9"></i>
                                    ) : (
                                        <i className="inline-block transform -rotate-90 cp-icon-arraySmallLeft text-9"></i>
                                    )}
                                </div>

                                <Transition
                                    className="border-grid bg-white transition-all origin-top top-12 min-w-[264px]  max-w-[calc(100vw-96px)] z-[12]  absolute w-full   flex flex-col px-6 pt-[25px] pb-[23px] space-y-[18px] label--bold"
                                    show={topHeader}
                                    enter="transition ease-in transform duration-300"
                                    enterFrom="transform opacity-0 scale-y-0"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <span className={`set-location-mobile ` + isLocationActive('sports', 'stamford')}  onClick={() => setSportsLocation('stamford')}>Sports &mdash; Stamford, CT</span>
                                    <span className={`set-location-mobile ` + isLocationActive('sports', 'chelsea')} onClick={() => setSportsLocation('chelsea')}>Sports &mdash; Chelsea, NY</span>
                                    <span className={`set-location-mobile ` + isLocationActive('sports', 'brooklyn')} onClick={() => setSportsLocation('brooklyn')}>Sports &mdash; Brooklyn, NY</span>
                                    <span className="set-location-mobile"  onClick={() => window.location.href = 'https://fitness.chelseapiers.com/' }>Fitness</span>
                                    <span className={`set-location-mobile ` + isLocationActive('events', 'stamford')}  onClick={() => setEventsLocation('stamford')}>Events &mdash; Stamford, CT</span>
                                    <span className={`set-location-mobile ` + isLocationActive('events', 'chelsea')}  onClick={() => setEventsLocation('chelsea')}>Events &mdash; Chelsea, NY</span>
                                    <span className={`set-location-mobile ` + isLocationActive('events', 'brooklyn')}  onClick={() => setEventsLocation('brooklyn')}>Events &mdash; Brooklyn, NY</span>
                                 </Transition>

                                <div
                                    className={` w-[100vw] top-0   min-w-full   border-grid ${
                                        topHeader ? 'bg-white' : 'bg-white'
                                    } `}
                                >
                                    <div
                                        className={`h-screen  w-full min-w-full relative transition-all duration-300  text-black-100 px-3 ${
                                            !topHeader
                                                ? 'bg-white'
                                                : 'bg-[rgba(0,0,0,0.7)]'
                                        } `}
                                    >
                                        <div
                                            className={`pt-[33px] pb-[168px] flex flex-col overflow-auto ${
                                                topHeader
                                                    ? ''
                                                    : 'border-r border-l'
                                            }  border-grid h-full  `}
                                        >
                                            <div className="flex flex-col justify-center space-y-6 lg:hidden label--largeBold">

                                                {top?.map((first) => { // First, top-level of the menu.
                                                    if (LocationManager.hideMenu(first)) return (<></>);

                                                    const secondaryItems = getMenuItems(items, first?.id) || [];
                                                    return (<>
                                                        <div 
                                                            className="flex flex-col justify-center h-full label--largeBold"
                                                            key={first?.id}
                                                            onClick={() => {
                                                                if (secondaryItems.length) {
                                                                    setActive(active===first?.id ? '' : first?.id);
                                                                    setTopHeader(false);
                                                                }
                                                            }}
                                                        >
                                                            <div className="relative flex items-center justify-between w-full h-full px-6 ">
                                                                {/* Top Level */}
                                                                <div className={`${ active === first?.id ? 'text-cobalt-100' : '' }`}>
                                                                    {!secondaryItems.length && // Top level is a link
                                                                        <div className={`${ active === first?.id ? 'text-cobalt-100' : '' }`}><a href={first?.url}>{' '}{first?.label}{' '}</a></div> 
                                                                    }    
                                                                    {!!secondaryItems.length && // Top level is a dropdown
                                                                        <div className={`${ active === first?.id ? 'text-cobalt-100' : '' }`}>{' '}{first?.label}{' '}</div> 
                                                                    }                
                                                                </div>    
                                                                {!!secondaryItems.length && // Top level is a dropdown
                                                                    <i className="cp-icon-arraySmallLeft text-9 leading-16 -rotate-90 transform text-cobalt-100  font-700  mr-[2px]"></i> 
                                                                }  
                                                            </div>

                                                            <Transition
                                                                ref={ref}
                                                                show={active === first?.id}
                                                                enter="transition ease-in transform duration-300"
                                                                leave="transition ease-out transform duration-300"
                                                                enterFrom="transform opacity-0 scale-y-0"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-y-0"
                                                                className=" origin-top w-full bg-[#F3F3F3] px-6 mt-[23px] space-y-[18px] "
                                                            >

                                                                    {!!secondaryItems.length && !isMegaMenu(first) && // Second level links with no third level
                                                                        <>
                                                                            <div className="origin-top w-full bg-[#F3F3F3] px-6 mt-[23px] space-y-[18px]">
                                                                                <div className="megaLevelTwo Inside the dropdown">
                                                                                    {secondaryItems.map((second) => {
                                                                                        return (<>

                                                                                            <a className="whitespace-nowrap flex items-center group label--bold mb-[15px]" href={second?.url}>
                                                                                                <span className="group-hover:text-cobalt-100">{second?.label}</span>
                                                                                            </a>
                                                                                            <div className="invisible hidden self-start w-6 bg-cobalt-100 w-[24px] group-hover:w-[28px]"></div>
                                                                                        </>);
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    {isMegaMenu(first)  && // Three-level menu
                                                                        <>
                                                                            {secondaryItems.map((second) => {
                                                                                if (LocationManager.hideMenu(second)) return (<></>);
                                                                                const tertiaryItems = getMenuItems(items, second?.id) || [];

                                                                                return (<>
                                                                                    <div className="origin-top w-full bg-[#F3F3F3] px-6 mt-[23px]  space-y-[18px]">
                                                                                        <div className="megaLevelOne">{' '}{second?.label}{' '}</div>
                                                                                        <div className="megaLevelTwo Inside the dropdown">
                                                                                            {tertiaryItems.map((third) => {
                                                                                                return (<>
                                                                                                    <a className="whitespace-nowrap flex items-center mb-[15px]" href={third?.url}>
                                                                                                        <span className="group-hover:text-cobalt-100">{third?.label}</span>
                                                                                                    </a>
                                                                                                    <div className="invisible hidden self-start w-6 bg-cobalt-100 w-[24px] group-hover:w-[28px]"></div>
                                                                                                    
                                                                                                </>);
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </>);
                                                                            })}
                                                                        </>
                                                                    }
                                                            </Transition>
                                                        </div>
                                                    </>)
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" bg-white max-w-[50px] p-[15px] border-b border-r border-grid" >
                                {/* <a href="/search"><img src="/icons/searchBlk.png"/></a> */}
                            </div>
                            <div
                                className={`flex w-full items-center justify-center h-full min-h-[48px]  min-w-[50px] max-w-[50px] border-b border-grid`}
                            >
                                <div className="flex flex-col">
                                    {subsite=='fitness' && 
                                        <NextNavLink href={'/my-membership'}>
                                            <i className="cp-icon-Account text-24 leading-24 cursor-pointer" 
                                            onClick={() => {
                                                setShowMobileHeader(false)
                                            }}
                                            />
                                        </NextNavLink>
                                    }
                                    {(subsite=='sports') &&
                                        <NextNavLink href={getMembershipLink(LocationManager.getLocation())}>
                                            <i className="cp-icon-Account text-24 leading-24 cursor-pointer" 
                                            onClick={() => {
                                                setShowMobileHeader(false)
                                            }}
                                            />
                                        </NextNavLink>
                                    }
                                </div>
                            </div>
                        </div>
                    </Transition>
                </header>
            )}
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[3.333%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[18.88866666666667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] left-[50%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[34.44433333333667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[18.88866666666667%] bg-grid"></div>
            <div className="xl:w-[1px]  fixed  h-full z-[3] right-[3.333%] bg-grid"></div>
            <div className="w-[1px] xl:hidden  z-[3]  fixed h-full bg-grid left-3"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid left-1/2"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid right-3"></div>
        </>
    );
}

export default MegaMenuHeader;
